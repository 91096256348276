import { LoanRoleDto } from "src/backend";
import { useGetLoanByIdQuery } from "src/services/loanApi";
import { useSearchUsersQuery } from "src/services/userApi";
import { createRecipientList } from "src/utils/loan/create-recipient-list";
import { isRoleABorrower } from "src/utils/user/is-role-a-borrower";
import { isRoleNonLender } from "src/utils/user/is-role-non-lender";

import { useLenderEmployees } from "../use-lender-employees";
import { useUser } from "../use-user";

interface GetRecipientsUsersProps {
    loanId: string;
    type: 'ALL' | 'BORROWERS' | 'LENDERS' | 'NOT_ON_LOAN';
}

export const useGetRecipientsUsers = (props: GetRecipientsUsersProps) => {
    const { data: loan = { loanRoles: [] as LoanRoleDto[], lender: { id: null } } } = useGetLoanByIdQuery(props.loanId, {
        skip: !props.loanId
    });
    const loggedInUser = useUser();

    const { data: searchUsers = [] } = useSearchUsersQuery({
        slice: ''
    }, {
        skip: !loggedInUser.isLender
    })

    const { employees = [] } = useLenderEmployees({
        lenderId: loan.lender.id,
        skip: !loggedInUser.isLender || !props.loanId
    });
    const loanRoleUserIds = loan?.loanRoles.map(({ user }) => user.id);
    const { loanRoles } = loan;

    const typeLoanRoles: LoanRoleDto[] = []
    if (props.type === 'ALL') {
        typeLoanRoles.push(...loanRoles);
    } else if (props.type === 'BORROWERS') {
        typeLoanRoles.push(...loanRoles.filter(({ role }) => isRoleNonLender(role)));
    } else if (props.type === 'LENDERS') {
        typeLoanRoles.push(...loanRoles.filter(({ role }) => !isRoleNonLender(role)));
    }

    const users = [
        ...typeLoanRoles.map(({ user, role }) => ({ ...user, roleDefault: role })),
        ...employees,
        ...searchUsers
    ]


    const usersWithoutDuplicates = users
        // remove duplicates
        .filter((user, index, self) => self.findIndex(({ id }) => id === user.id) === index)
        .filter(user => {
            if (props.type === 'NOT_ON_LOAN') {
                return !loanRoleUserIds.includes(user.id);
            } else {
                return true;
            }
        })

    return {
        users: usersWithoutDuplicates,
        recipientsOptions: createRecipientList(typeLoanRoles, employees, loggedInUser.user, searchUsers)
    } as const;
};