import Text from "components/dist/atoms/Text";
import { useDeferredValue, useState } from "react";
import { toast } from "react-toastify";
import { AppUserDTO2 } from "src/backend";
import { useUser } from "src/hooks/use-user";
import { useAddLenderMutation, useEditLenderMutation, useGetLoanByIdQuery } from "src/services/loanApi";
import { useCreateSharedInfoElementMutation } from "src/services/packageApi";
import { getLoan } from "src/slices/loan";
import { useDispatch } from "src/store";
import { pluralize } from "src/utils/pluralize";
import { getUserDisplayName } from 'src/utils/user/get-user-display-name';
import { isRoleNonLender } from "src/utils/user/is-role-non-lender";

import { ElementsSharingDialogProps } from "./elements-sharing-dialog.types";

export const useElementsSharingDialogState = (props: ElementsSharingDialogProps['props']) => {
    const userState = useUser();
    const dispatch = useDispatch()
    const [userElementsSharing, setUserElementsSharing] = useState<Record<string, ElementsSharingDialogProps['shareType'] | "">>({
        ...props.shareWithUsers.reduce((acc, next) => ({
            ...acc,
            [next.id]: "ASSIGN"
        }), {}),
        ...props.emails.reduce((acc, next) => ({
            ...acc,
            [next]: "ASSIGN"
        }), {})
    });
    const { data: loan } = useGetLoanByIdQuery(props.loanId, {
        skip: !props.loanId
    });
    const [addLenderToLoan, { isLoading: isLoadingAddMutation }] = useAddLenderMutation();
    const [createSharedInfoElement, { isLoading: isLoadingShareMutation }] = useCreateSharedInfoElementMutation();
    const [editLender, { isLoading: isLoadingEditLenderMutation }] = useEditLenderMutation();
    const isLoading = useDeferredValue(isLoadingAddMutation || isLoadingShareMutation || isLoadingEditLenderMutation || props.loading)

    const usersOnTheLoanKeyMap = loan?.loanRoles.reduce((acc, loanRole) => {
        acc[loanRole.user.id] = loanRole.user;
        return acc;
    }, {} as Record<string, AppUserDTO2>);

    // a map of element ids that are not assigned to user ids
    const elementIdSharedInfoMap = props.elements.reduce((acc, element) => {
        const notAssignedUsers = element.sharedInfo.map(sharedInfo => sharedInfo.sharedByUser)
        return {
            ...acc,
            [element.id]: notAssignedUsers.map(user => user.id)
        }
    }, {} as Record<string, string[]>);

    const usersWithUnAssignedElements = props.elements.reduce((acc, element) => {
        const notAssignedUsers = props.shareWithUsers.filter(user => isRoleNonLender(user.roleDefault) && !elementIdSharedInfoMap[element.id].includes(user.id));
        return {
            ...acc,
            [element.id]: notAssignedUsers
        }
    }, {} as Record<string, ElementsSharingDialogProps['props']['shareWithUsers']>);

    const elementsWithUnAssignedUsers = props.elements.filter(element => usersWithUnAssignedElements[element.id].length > 0 || props.emails.length > 0);
    const uniqueUsersWithUnAssignedElements = Object.values(usersWithUnAssignedElements).flat().filter((user, index, users) => users.indexOf(user) === index);
    const usersOnTheLoanWithUnAssignedElements = uniqueUsersWithUnAssignedElements.filter(user => usersOnTheLoanKeyMap[user.id]);
    const usersNotOnTheLoanWithUnAssignedElements = props.shareWithUsers.filter(user => !user.isOnLoan)
    const invisibleUsers = props.shareWithUsers.filter(user => !user.isVisibleToBorrower);

    const isSenderAloneInMessage = (props.shareWithUsers.length + props.emails.length) === 1 && props.shareWithUsers[0]?.id === userState.user.id;

    const isSenderInvisible = loan?.loanRoles.find(loanRole => loanRole.user.id === userState.user.id)?.visibleToBorrower === false;
    const isSenderNotOnTheLoan = !usersOnTheLoanKeyMap[userState.user.id];

    const invisibleUsersKeyMap = invisibleUsers.reduce((acc, user) => {
        acc[user.id] = user;
        return acc;
    }, {} as Record<string, AppUserDTO2>);

    const groups: ElementsSharingDialogProps['groups'][] = []

    if (usersOnTheLoanWithUnAssignedElements.length > 0 || invisibleUsers.length > 0) {
        groups.push({
            key: "on-the-loan",
            title: "On the loan",
            users: [...usersOnTheLoanWithUnAssignedElements, ...invisibleUsers],
            emails: []
        });
    }
    if (usersNotOnTheLoanWithUnAssignedElements.length > 0 || props.emails.length > 0) {
        groups.push({
            key: "not-on-the-loan",
            title: "Not on the loan",
            users: usersNotOnTheLoanWithUnAssignedElements,
            emails: [...props.emails]
        });
    }

    const onSelectValueChange = (value: ElementsSharingDialogProps['shareType'] | "", userId: string) => {
        // set value of user and remove value of the user group
        setUserElementsSharing(prev => ({
            ...prev,
            [userId]: value
        }));
    };

    const onSendOnlyClick = async () => {
        toast.success(getToastMessage({
            elements: [],
            assignToUsers: [],
            addedUsers: [],
            invisibleUsers,
            isSenderAloneInMessage,
            emails: props.emails
        }));
        // refresh old redux loan state
        // TODO remove this when we refactor all loan request usage to RTK QUERY
        await dispatch(getLoan(props.loanId, false, true));
        props.onConfirm([]);
    };

    const onContinueClick = async () => {
        const addedUsers = []
        const assignToUsers = []
        const invisibleUsers = []
        const promises = props.shareWithUsers
            .map(async (user) => {
                // if user is not on the loan we need to add him to the loan
                if (!usersOnTheLoanKeyMap[user.id]) {
                    await addLenderToLoan({
                        loanId: props.loanId,
                        lenderUser: {
                            contactVisibleToBorrowers: false,
                            contactVisibleToLenders: false,
                            appUser: user.id,
                            canAcceptFiles: true,
                            borrowerType: null,
                            leadBorrower: false,
                            leadLender: false,
                            newToLoan: true,
                            /// set role to user roleDefault
                            role: user.roleDefault,
                            visibleToBorrower: true,
                            contactRelation: null
                        }
                    });
                    addedUsers.push(user);
                }
                if (invisibleUsersKeyMap[user.id]) {
                    await editLender({
                        loanId: props.loanId,
                        lenderUser: {
                            contactVisibleToBorrowers: false,
                            contactVisibleToLenders: false,
                            appUser: user.id,
                            leadLender: user.role === 'LEAD_LENDER',
                            leadBorrower: user.role === 'LEAD_BORROWER',
                            newToLoan: false,
                            role: user.roleDefault,
                            canAcceptFiles: null,
                            visibleToBorrower: true,
                            borrowerType: null,
                            contactRelation: null,
                        }
                    }).unwrap()
                    invisibleUsers.push(user);
                }
                await createSharedInfoElement({
                    shares: props.elements.map(element => ({
                        id: null,
                        infoId: element.id,
                        loanId: element.loanId,
                        permissions: ['VIEW'],
                        sharedByUserId: null,
                        sharedWithUserId: user.id
                    }))
                });
                assignToUsers.push(user);
            });
        //
        await Promise.all(promises);
        toast.success(getToastMessage({
            elements: props.elements,
            assignToUsers,
            addedUsers,
            invisibleUsers,
            isSenderAloneInMessage,
            emails: props.emails
        }));
        // refresh old redux loan state
        // TODO remove this when we refactor all loan request usage to RTK QUERY
        await dispatch(getLoan(props.loanId, false, true));
        props.onConfirm([]);
    };

    const onGroupCheckedChange = (checked: boolean | 'indeterminate', groupKey: string) => {
        const groupUsers = groups.find(group => group.key === groupKey)?.users;
        const emailExist = props.emails.findIndex(email => email === groupKey)
        if (!groupUsers && !emailExist) {
            return;
        }
        setUserElementsSharing(prev => ({
            ...prev,
            ...groupUsers.reduce((acc, user) => ({
                ...acc,
                [user.id]: checked ? "ASSIGN" : ""
            }), {}),
            ...props.emails.reduce((acc, email) => ({
                ...acc,
                [email]: checked ? "ASSIGN" : ""
            }), {})
        }));
    };
    return {
        onSelectValueChange,
        onContinueClick,
        onSendOnlyClick,
        onGroupCheckedChange,
        dialogTitle: getDialogTitle({
            notAssignedElementsCount: elementsWithUnAssignedUsers.length,
            usersNotOnTheLoanCount: usersNotOnTheLoanWithUnAssignedElements.length,
            invisibleUsersCount: invisibleUsers.length,
            isSenderAloneInMessage,
            isSenderInvisible,
            isSenderNotOnTheLoan,
            emails: props.emails
        }),
        loggedInUserId: userState.user.id,
        isLoading,
        sendActionText: elementsWithUnAssignedUsers.length > 0 ? "Send and Assign" : "Send Message",
        userElementsSharing,
        groups,
        hasElements: elementsWithUnAssignedUsers.length > 0,
        totalUsers: groups.reduce((acc, group) => acc + group.users.length, 0) + props.emails.length
    } as const;
}

// on the loan title
// {{n}} items are not assigned to 3 users on the loan. Do you want to share only or assign items?
// not on the loan title
// {{n}} items are not assigned to 3 users not on the loan. Do you want to share only or assign items? Assigning automatically adds user to the loan.
// on and not on the loan title
// {{n}} items are not assigned to 6 users. Do you want to share only or assign items? Assigning automatically adds user to the loan.

const getDialogTitle = (args: {
    notAssignedElementsCount: number,
    usersNotOnTheLoanCount: number,
    emails: string[],
    invisibleUsersCount: number,
    isSenderAloneInMessage: boolean,
    isSenderInvisible: boolean
    isSenderNotOnTheLoan: boolean
}): React.ReactNode => {
    const { notAssignedElementsCount, usersNotOnTheLoanCount } = args;
    const invisibleUsers = args.invisibleUsersCount > 0;
    const usersNotOnTheLoan = usersNotOnTheLoanCount > 0;
    if (args.isSenderAloneInMessage && notAssignedElementsCount === 0 && invisibleUsers && !usersNotOnTheLoan) {
        return `You're not visible to borrowers. Sending will make you visible for this message. Do you want to send message?`
    } else if (args.isSenderAloneInMessage && notAssignedElementsCount === 0 && usersNotOnTheLoan && !invisibleUsers) {
        return `You're not on the loan. Sending a message will add you to the loan. Do you want to send message?`
    } else if (notAssignedElementsCount > 0 && usersNotOnTheLoan && invisibleUsers) {
        return `${notAssignedElementsCount} ${pluralize('item', notAssignedElementsCount)} are not assigned to these users and ${args.invisibleUsersCount > 1 ? "some users are" : "a user is"} not visible to borrowers. Sending will add selected users to the loan and make them visible for this message. Do you want to send and assign ${pluralize('item', notAssignedElementsCount)}?`
    } else if (notAssignedElementsCount === 0 && usersNotOnTheLoan && invisibleUsers) {
        return `${args.isSenderNotOnTheLoan ? 'You and ' : ''}These users are not on the loan and some are not visible to borrowers. Sending will add selected users to the loan and make them visible for this message${args.emails.length > 0 ? ', or add as contact on your profile. ' : ''} Do you want to send message?`
    } else if (notAssignedElementsCount === 0 && !usersNotOnTheLoan && invisibleUsers) {
        return `${args.isSenderInvisible ? 'You and ' : ''}These users are not visible to borrowers. Sending will make them visible for this message. Do you want to send message?`
    } else if (notAssignedElementsCount > 0 && usersNotOnTheLoan && !invisibleUsers) {
        return `${notAssignedElementsCount} ${pluralize('item', notAssignedElementsCount)} are not assigned to these users. Sending will add selected users to the loan. Do you want to send and assign ${pluralize('item', notAssignedElementsCount)}?`
    } else if (notAssignedElementsCount > 0 && !usersNotOnTheLoan && !invisibleUsers && args.emails.length === 0) {
        return `${notAssignedElementsCount} ${pluralize('item', notAssignedElementsCount)} are not assigned to these users. Do you want to send and assign ${pluralize('item', notAssignedElementsCount)}?`
    } else if (notAssignedElementsCount === 0 && args.emails.length === 1 && !usersNotOnTheLoan && !invisibleUsers) {
        return <><Text as='span' weight='medium' size='sm'>{args.emails[0]}</Text> is not on the loan and organization.The user will be added as a contact on your profile </>
    } else if (notAssignedElementsCount > 0 && args.emails.length === 1 && !usersNotOnTheLoan && !invisibleUsers) {
        return <><Text as='span' weight='medium' size='sm'>{args.emails[0]}</Text>  is not on the loan and organization. Sending will add selected user to the loan as a contact on your profile. Do you want to send and assign items? </>
    } else if (notAssignedElementsCount === 0 && usersNotOnTheLoan && !invisibleUsers) {
        return `${args.isSenderNotOnTheLoan ? 'You and ' : ''}${(usersNotOnTheLoanCount + args.emails.length) > 1 ? "These users are" : "This user is"} not on the loan. Sending will add selected users to the loan${args.emails.length > 0 ? ' or add as contact on your profile. ' : ''} Do you want to send message?`
    } else if (notAssignedElementsCount > 0 && !usersNotOnTheLoan && invisibleUsers) {
        return `${notAssignedElementsCount} ${pluralize('item', notAssignedElementsCount)} are not assigned to these users and ${args.invisibleUsersCount > 1 ? "some users are" : "a user is"} not visible to borrowers. Sending will make ${args.invisibleUsersCount > 1 ? "them" : "him"} visible for this message. Do you want to send and assign ${pluralize('item', notAssignedElementsCount)}?`
    } else if (notAssignedElementsCount > 0 && !usersNotOnTheLoan && !invisibleUsers && args.emails.length > 1) {
        return `These users are not on the loan. Sending will add selected users to the loan or as a contact on your profile. Do you want to send and assign items?`;
    }
    return ""
}

const getToastMessage = (args: {
    elements: ElementsSharingDialogProps['props']['elements'],
    assignToUsers: ElementsSharingDialogProps['props']['shareWithUsers'],
    addedUsers: ElementsSharingDialogProps['props']['shareWithUsers'],
    invisibleUsers: ElementsSharingDialogProps['props']['shareWithUsers'],
    isSenderAloneInMessage: boolean,
    emails: string[]
}): string => {
    const assignedElementsCount = args.elements.length;
    const usersMadeVisibleCount = args.invisibleUsers.length;
    const usersAddedToLoanCount = args.addedUsers.length > 0;
    // add cases where user is alone in the message and is invisible or not on the loan
    if (args.isSenderAloneInMessage && assignedElementsCount === 0 && usersMadeVisibleCount && !usersAddedToLoanCount) {
        return `You have been made visible to borrowers.`
    } else if (args.isSenderAloneInMessage && assignedElementsCount === 0 && !usersMadeVisibleCount && usersAddedToLoanCount) {
        return `You have been added to the loan.`
    } else if (assignedElementsCount > 0 && usersAddedToLoanCount && usersMadeVisibleCount) {
        return `${getCountOrItemTitle(args.elements)} sent and assigned to ${getCountOrUserName(args.assignToUsers)}. ${getCountOrUserName(args.invisibleUsers)} made visible for the message and ${getCountOrUserName(args.addedUsers)} added to the loan.`;
    } else if (assignedElementsCount === 0 && usersAddedToLoanCount && usersMadeVisibleCount) {
        // 6
        return `${getCountOrUserName(args.addedUsers)} added to the loan. ${getCountOrUserName(args.invisibleUsers)} made visible.`;
    } else if (assignedElementsCount === 0 && !usersAddedToLoanCount && usersMadeVisibleCount) {
        // 4
        return `${getCountOrUserName(args.invisibleUsers)} made visible.`;
    } else if (assignedElementsCount > 0 && usersAddedToLoanCount && !usersMadeVisibleCount) {
        // 2
        return `${getCountOrItemTitle(args.elements)} sent and assigned to ${getCountOrUserName(args.assignToUsers)}. ${getCountOrUserName(args.addedUsers)} added to the loan`
    } else if (assignedElementsCount > 0 && !usersAddedToLoanCount && !usersMadeVisibleCount) {
        // 1
        return `${getCountOrItemTitle(args.elements)} sent and assigned to ${getCountOrUserName(args.assignToUsers)}`
    } else if (assignedElementsCount === 0 && usersAddedToLoanCount && !usersMadeVisibleCount) {
        // 3
        return `${getCountOrUserName(args.assignToUsers)}. ${getCountOrUserName(args.addedUsers)} added to the loan`
    } else if (assignedElementsCount > 0 && !usersAddedToLoanCount && usersMadeVisibleCount) {
        // 5
        return `${getCountOrItemTitle(args.elements)} sent and assigned to ${getCountOrUserName(args.assignToUsers)}. ${getCountOrUserName(args.invisibleUsers)} made visible`;
    } else if (assignedElementsCount === 0 && !usersAddedToLoanCount && !usersMadeVisibleCount && args.emails.length > 0) {
        return `${args.emails.length === 1 ? args.emails[0] : `${args.emails.length} users`} added as a contact on your profile.`
    }
    return ""
}


const getCountOrItemTitle = (elements: ElementsSharingDialogProps['props']['elements']): string => {
    if (elements.length > 1) {
        return `${elements.length} items`
    } else if (elements.length === 1) {
        return elements[0].title;
    } else {
        return ""
    }

}

const getCountOrUserName = (users: ElementsSharingDialogProps['props']['shareWithUsers']): string => {
    if (users.length > 1) {
        return `${users.length} users`
    } else if (users.length === 1) {
        return getUserDisplayName(users[0]);
    } else {
        return ""
    }
}